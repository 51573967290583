import { BrowserRouter as Router,Routes, Route} from 'react-router-dom';
import React, { useState, useEffect,useContext } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import AdminPage from "./components/AdminPage"
import { Team } from "./components/Team";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import SignIn from './components/SignIn';
import { UserContext} from './context/UserContext';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

function App() {
  const [landingPageData, setLandingPageData] = useState({});
  const { role } = useContext(UserContext);  
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  

  return (
    <Router>
      {role!=="admin"?
      <Routes>
        <Route path='/' element={ <div>
          <Navigation />
          <Header data={landingPageData.Header} />
          <Features data={landingPageData.Features} />
          <About data={landingPageData.About} />
          <Gallery data={landingPageData.Gallery} />
          <Testimonials data={landingPageData.Testimonials} />
          <Team data={landingPageData.Team} />
        </div>}>
        </Route>
        <Route path='/admin' element={ <SignIn></SignIn>}>
        </Route>
      </Routes>
      :<Routes>
        <Route path='/' element={ <div>
        <AdminPage></AdminPage>
        </div>}>
        </Route>
      </Routes>
      }

    </Router>

  );
}

export default App;