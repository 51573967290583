import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ThemedApp from './ThemedApp';
import { Provider } from 'react-redux'
import store from './store/Store'
import { UserProvider} from './context/UserContext';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <UserProvider>
        <div style={{ overflowY: 'auto', maxHeight: '100vh' }}>
          <ThemedApp />
        </div>
      </UserProvider>
    </Provider>
);

