import { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import {Button } from '@mui/material';
import { db } from '../firebase';
import Announcements from './Announcements';
import EditHours from './EditHours';

function AdminPage() {
  const [announcements, setAnnouncements] = useState([]);
  const [storeHours, setStoreHours] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const announcementsSnapshot = await getDocs(collection(db, 'announcements'));
      const announcementsData = announcementsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setAnnouncements(announcementsData);

      const storeHoursSnapshot = await getDocs(collection(db, 'storeHours'));
      const storeHoursData = storeHoursSnapshot.docs[0].data();
      console.log(storeHoursData);
      setStoreHours(storeHoursData);
    };

    fetchData();
  }, []);

  const handleAddAnnouncement = async (newAnnouncement) => {
    try {
      const announcementRef = await addDoc(collection(db, 'announcements'), {
        text: newAnnouncement.text,
        image: newAnnouncement.image,
      });

      setAnnouncements([...announcements, { id: announcementRef.id, ...newAnnouncement }]);
    } catch (error) {
      console.error('Error adding announcement:', error);
    }
  };

  const handleUpdateAnnouncement = async (updatedAnnouncement) => {
    try {
      const announcementRef = doc(db, 'announcements', updatedAnnouncement.id);
      await updateDoc(announcementRef, {
        text: updatedAnnouncement.text,
        image: updatedAnnouncement.image,
      });

      setAnnouncements(
        announcements.map((announcement) =>
          announcement.id === updatedAnnouncement.id ? updatedAnnouncement : announcement
        )
      );
    } catch (error) {
      console.error('Error updating announcement:', error);
    }
  };

  const handleDeleteAnnouncement = async (id) => {
    try {
      await deleteDoc(doc(db, 'announcements', id));
      setAnnouncements(announcements.filter((announcement) => announcement.id !== id));
    } catch (error) {
      console.error('Error deleting announcement:', error);
    }
  };

  return (
    <div width="100%">
      <h1>Welcome to Our Store</h1>
      <Button variant="contained" onClick={ ()=>{localStorage.removeItem("role");window.location.assign("/")}} sx={{ fontSize: '1.2rem' }}>
            Log Out
          </Button>
      <Announcements
        announcements={announcements}
        onAddAnnouncement={handleAddAnnouncement}
        onUpdateAnnouncement={handleUpdateAnnouncement}
        onDeleteAnnouncement={handleDeleteAnnouncement}
      />
      <EditHours daysOfWeek={storeHours}/>
    </div>
  );
}

export default AdminPage;