import React, { useContext, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { updateLocalUserData } from '../features/user/UserSlice';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';

import {  signInWithEmailAndPassword  } from 'firebase/auth';
import { auth, getUserData } from '../firebase';
import { NotificationContext } from '../context/NotificationContext';
import LoadingSpinner from '../components/LoadingSpinner';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn() {
  const { setRole, setUserId, setFirebaseAuth } = useContext(UserContext); 
  const { sendMessage } = useContext(NotificationContext);  
  const [loading] = useState(false);

  const navigate = useNavigate()
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      // Sign in to firebase to grab credentials
      const userCredential = await signInWithEmailAndPassword(auth, data.get('email'), data.get('password'));
      setFirebaseAuth(userCredential)
      // Fetch user data from firestore
      const user = userCredential.user;
      const userData = await getUserData(user.uid);
      sendMessage("success", `Signed in as ${userData.firstName} ${userData.lastName}`);
      // Update user state and set user role
      dispatch(updateLocalUserData(userData));
      setRole(userData.role);
      setUserId(user.uid);
      localStorage.setItem("role", userData.role)
      //localStorage.setItem("role", 'admin')
      localStorage.setItem("userId", user.uid)

      // Reload to see home page
      navigate("/");
      window.location.reload();
    } catch (error) {
      // Sign in failed
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
      sendMessage("error", "Issue with login")
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container maxWidth="xs" component="main">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
            </div>
          </div>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
           
          </Box>
        </Box>
        <Box>
          <LoadingSpinner
            showWhiteScreen={loading}
            backdropText={"Loading..."}
          />
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}