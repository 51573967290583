// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { doc,  getDoc, getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBE-Sdese4Zeu1Ox72qpHnd1szMh1KVnA0",
  authDomain: "ideli-26e7c.firebaseapp.com",
  projectId: "ideli-26e7c",
  storageBucket: "ideli-26e7c.appspot.com",
  messagingSenderId: "618467916103",
  appId: "1:618467916103:web:dba83792ffbf2f2be67462",
  measurementId: "G-KQTZK4T1B4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
//const analytics = getAnalytics(app);

export { db, auth };

function transformObject(obj) {
  const transformedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && typeof obj[key] === 'object' && obj[key].hasOwnProperty('stringValue')) {
      transformedObj[key] = obj[key].stringValue;
    }
  }

  return transformedObj;
}

export async function getUserData(userId) {
  try {
    const userDataDocRef = doc(db, "userData", userId);
    const userDataDocSnap = await getDoc(userDataDocRef);

    const userDataObject = userDataDocSnap._document.data.value.mapValue.fields;

    return transformObject(userDataObject);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage)

    return null;
  }
}