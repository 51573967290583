import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase'; 
const AnnouncementList = () => {
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    // Fetch announcements from Firestore
    const fetchAnnouncements = async () => {
      try {
        const announcementsSnapshot = await getDocs(collection(db, 'announcements'));
        const announcementsData = announcementsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAnnouncements(announcementsData);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px'}}>
      <Typography variant='h2' align="center"  sx={{color: "white"}}>Announcements</Typography>
      {announcements.map((announcement) => (
        <Card key={announcement.id} sx={{ marginBottom: '32px', width: '100%', bgcolor: "#1b2f49" }}>
          <CardMedia
            component="img"
            //height="200"
            image={announcement.image}
            alt={announcement.text}
          />
          <CardContent>
            <Typography variant="h4" align="center" sx={{color: "white"}}>
              {announcement.text}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default AnnouncementList;