import { useContext, useEffect, useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Switch, Grid, Box, Typography, Button } from '@mui/material';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db,  } from '../firebase';
import { NotificationContext } from '../context/NotificationContext';

function EditHours() {
  const [hours, setHours] = useState({});
  const [hoursId, setHoursId] = useState('');
  const orderedDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const { sendMessage } = useContext(NotificationContext); 

  useEffect(() => {
    const fetchData = async () => {
      const storeHoursSnapshot = await getDocs(collection(db, 'storeHours'));
      const storeHoursData = storeHoursSnapshot.docs[0].data();
      const docId = storeHoursSnapshot.docs[0].id;

      setHours(storeHoursData);
      setHoursId(docId);
    };

    fetchData();
  }, []);

  const TimeSelect = ({ value, onChange }) => {
    const times = Array.from({ length: 24 }, (_, i) => `${i.toString().padStart(2, '0')}:00`);
  
    return (
      <FormControl fullWidth>
        <InputLabel sx={{ fontSize: '2rem' }}>Time</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          sx={{ fontSize: '1.25rem', color: 'black' }}
        >
          {times.map(time => (
            <MenuItem key={time} value={time} sx={{ fontSize: '1.25rem' }}>{time}</MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  

  const handleChange = (day, key, value) => {
    setHours(prevHours => ({
      ...prevHours,
      [day]: {
        ...prevHours[day],
        [key]: value,
      },
    }));
  };

  const saveStoreHours = async () => {
    try {
      if (hoursId) {
        const storeHoursDocRef = doc(db, 'storeHours', hoursId);
        await updateDoc(storeHoursDocRef, hours);
        sendMessage('success', 'Store hours updated successfully');
      }
    } catch (error) {
      console.error("Error updating store hours: ", error);
      sendMessage('error', 'Error updating store hours');
    }
  }

  return (
    <Box sx={{ p: 2 }}>
      <h2>Store Hours</h2>
      {Object.keys(hours).length > 0 && orderedDays.map(day => (
        <Box key={day} sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom>{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Switch
                checked={hours[day].closed}
                onChange={e => handleChange(day, 'closed', e.target.checked)}
                name="closed"
                color="primary"
              />
              Closed
            </Grid>
            <Grid item xs={12} sm={4}>
              <TimeSelect
                value={hours[day].openingTime}
                onChange={e => handleChange(day, 'openingTime', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TimeSelect
                value={hours[day].closingTime}
                onChange={e => handleChange(day, 'closingTime', e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
      ))}
      <Button variant='contained' onClick={saveStoreHours}>Save Store Hours</Button>
    </Box>
  );
}

export default EditHours;