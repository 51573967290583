import * as React from 'react';
//import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { UserContext} from './context/UserContext';
import { grey } from '@mui/material/colors';
import App from './App';

function ThemedApp() {
    //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const ColorModeContext = React.createContext({ toggleColorMode: () => {} });
    const { userColorMode } = React.useContext(UserContext);  

    const [mode, setMode] = React.useState((userColorMode ? userColorMode : 'light'));
    const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      [],
    );

    React.useEffect(() => {
        setMode(userColorMode)
    }, [userColorMode])
  
    const theme = React.useMemo(
      () =>
        createTheme({
          palette: { // Custom Palette: https://mui.com/material-ui/customization/dark-mode/#dark-mode-with-a-custom-palette
            mode,
            ...(mode === 'light'
              ? {
                // palette values for light mode
                primary: {
                  main: '#62b761'
                },
                borderColor: {
                  main: '#62b761', 
                },
              }
              : {
                // palette values for dark mode
                primary: {
                  main: '#62b761'
                },
                divider: grey[800],
                background: {
                  default: '#101d32',
                  paper: '#1b2f49',
                },
                text: {
                  primary: '#ffffff',
                  secondary: grey[500],
                },
                borderColor: {
                  main: '#62b761', 
                },
            }),
          },
          typography: {
            fontFamily: 'din-2014-rounded-variable, sans-serif;', 
          },
          components: {
            MuiButton: {
              defaultProps: {
                disableElevation: true,

              },
              styleOverrides: {
                root: {
                  fontWeight: 600, 
                },
              },
            }
          }
        }),
      [mode],
    );
  
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <App ColorModeContext={ColorModeContext} />
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  };

  export default ThemedApp;