import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserData } from '../firebase';
import { updateLocalUserData } from '../features/user/UserSlice';

export const UserContext = React.createContext({
  firebaseAuth: {},
});


export function UserProvider(props) {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null); // null, newStudent, student, coach, admin
  const [userId, setUserId] = useState(null)
  const [firebaseAuth, setFirebaseAuth] = useState({});
  const [userColorMode, setUserColorMode] = useState('light');
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const userData = await getUserData(userId)
      if(userData) {
        setUserColorMode(userData.colorMode);
        localStorage.setItem('colorMode', userData.colorMode)
      } else if (localStorage.getItem('colorMode')) {
        setUserColorMode(localStorage.getItem('colorMode'));
      }
      return userData;
    } catch (error) {
      console.log(error)
    }
    return {};
  }

  useEffect(() => {
    console.log(userId)
    if(userId) {
      fetchData().then((result) => {
        if(result) {
          console.log(result)
          dispatch(updateLocalUserData(result));
        }
      });
    } else {
      setUserId(localStorage.getItem("userId"))
    }
  //eslint-disable-next-line
  }, [userId])

  useEffect(() => {
    let currentRole = role;
    let currentUserId = userId;
    console.log(currentRole)
    if (localStorage.getItem('role') !== null && role === null) {
      currentRole = localStorage.getItem('role');
      console.log(currentRole)
      setRole(currentRole)
    } 
    if (localStorage.getItem('userId') !== null && userId === null) {
      currentUserId = localStorage.getItem('userId');
      setUserId(currentUserId);
    }
    

    setLoading(false);
  }, [role, userId]);

  if (loading) {
    return <p>loading...</p>;
  } else {
    return (
      <UserContext.Provider value={{ role, setRole, userColorMode, setUserColorMode, firebaseAuth, setFirebaseAuth, loading, setLoading, userId, setUserId}}>
        {props.children}
      </UserContext.Provider>
    );
  }
}