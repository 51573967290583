import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';

const StoreHours = ({ hours }) => {
  const formatStoreHours = (day) => {
    const dayHours = hours[day];
    if (dayHours && !dayHours.closed) {
      const openingTime = convertTo12HourFormat(dayHours.openingTime);
      const closingTime = convertTo12HourFormat(dayHours.closingTime);
      return `${openingTime} TO ${closingTime}`;
    }
    return 'CLOSED';
  };

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(':');
    let hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
    return `${hour}:${minutes} ${ampm}`;
  };

  return (
    <Card sx={{bgcolor: "#1b2f49"}}>
      <CardContent>
        <Typography variant="h4" component="div" align="center" gutterBottom color="white">
          BUSINESS HOURS
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={4} sx={{ backgroundColor: '#1976d2', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              Monday:
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ backgroundColor: '#2196f3', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {formatStoreHours('monday')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: '#424242', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              Tuesday:
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ backgroundColor: '#616161', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {formatStoreHours('tuesday')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: '#1976d2', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              Wednesday:
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ backgroundColor: '#2196f3', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {formatStoreHours('wednesday')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: '#424242', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              Thursday:
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ backgroundColor: '#616161', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {formatStoreHours('thursday')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: '#1976d2', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              Friday:
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ backgroundColor: '#2196f3', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {formatStoreHours('friday')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: '#424242', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              Saturday:
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ backgroundColor: '#616161', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {formatStoreHours('saturday')}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ backgroundColor: '#1976d2', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              Sunday:
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{ backgroundColor: '#2196f3', padding: '4px' }}>
            <Typography variant="body1" sx={{ color: '#fff' }}>
              {formatStoreHours('sunday')}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StoreHours;