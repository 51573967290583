import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, TextField, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const AnnouncementCard = ({ announcement, onDelete }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="div">
          {announcement.text}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={() => onDelete(announcement.id)}
          sx={{ fontSize: '1.2rem' }}
        >
          Delete
        </Button>
      </CardContent>
    </Card>
  );
};

const Announcements = ({ announcements, onAddAnnouncement, onUpdateAnnouncement, onDeleteAnnouncement }) => {
  const [newAnnouncement, setNewAnnouncement] = useState({ text: '', image: null });
  const [imagePreview, setImagePreview] = useState(null);

  const handleAddAnnouncement = () => {
    if (newAnnouncement.text && newAnnouncement.image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageBinaryString = reader.result;
        onAddAnnouncement({ ...newAnnouncement, image: imageBinaryString });
        setNewAnnouncement({ text: '', image: null });
        setImagePreview(null);
      };
      reader.onerror = (error) => {
        console.error('Error converting image to binary string:', error);
      };
      reader.readAsDataURL(newAnnouncement.image);
    }
  };

  const handleDeleteAnnouncement = (id) => {
    onDeleteAnnouncement(id);
  };

  const handleInputChange = (event) => {
    setNewAnnouncement({ ...newAnnouncement, text: event.target.value });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setNewAnnouncement({ ...newAnnouncement, image: file });
    setImagePreview(URL.createObjectURL(file));
  };

  return (
    <div>
      <Typography variant="h2">Announcements</Typography>
      {announcements.map((announcement) => (
        <div key={announcement.id}>
          <img src={announcement.image} alt={announcement.text} />
          <AnnouncementCard announcement={announcement} onDelete={handleDeleteAnnouncement} />
        </div>
      ))}
      <div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <TextField
            label="Announcement Text"
            name="text"
            value={newAnnouncement.text}
            onChange={handleInputChange}
            sx={{
              '& .MuiInputBase-input': {
                color: 'black',
                fontSize: '1.4rem',
              },
              '& .MuiInputLabel-root': {
                color: 'black',
                fontSize: '1.4rem',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'black',
                },
                '&:hover fieldset': {
                  borderColor: 'black',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'black',
                },
              },
              width: '400px',
            }}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="upload-button"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="upload-button">
            <Button variant="contained" component="span" startIcon={<CloudUploadIcon />} sx={{ fontSize: '1.2rem' }}>
              Upload Image
            </Button>
          </label>
          {imagePreview && (
            <div>
              <img src={imagePreview} alt="Uploaded" style={{ maxWidth: '200px', marginTop: '10px' }} />
            </div>
          )}
          <Button variant="contained" onClick={handleAddAnnouncement} sx={{ fontSize: '1.2rem' }}>
            Add Announcement
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Announcements;