import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  uid: '',
  role: '', // admin, coach, student, newStudent
  profilePicture: '',
  firstName: '',
  lastName: '',
  email: '',
  parentEmail: '',
  preSelectedCoach: '',
  programs:[],
  colorMode: '',
  calendlyLink: '',
}

export const user = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    updateLocalUserData: (state, action) => {
      const newData = action.payload;
      Object.assign(state, newData);
    }
  },
})

export const { updateName, updateLocalUserData } = user.actions

export default user.reducer
