import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

import StoreHours from './StoreHours';

function StoreHoursComponent() {
  const [storeHoursData, setStoreHoursData] = useState(null);

  const fetchStoreHoursData = async () => {
    const storeHoursSnapshot = await getDocs(collection(db, 'storeHours'));
    const storeHoursData = storeHoursSnapshot.docs[0].data();
    
    console.log(storeHoursData)
    setStoreHoursData(storeHoursData);
  
};

  useEffect(() => {
    fetchStoreHoursData();
  }, []);

  return (
    <div>
      {storeHoursData ? (
        <StoreHours hours={storeHoursData} />
      ) : (
        <p>Loading store hours...</p>
      )}
    </div>
  );
}

export default StoreHoursComponent;