import * as React from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

export default function LoadingSpinner(props) {
    const { showBackdrop, showWhiteScreen, backdropText } = props;

    return (
        <div>
            {showBackdrop && (
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={!showWhiteScreen}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}
                    >
                        <CircularProgress color="inherit" sx={{m:2}} />
                        <Typography variant="46" component="div" color="white">
                            {backdropText}
                        </Typography>
                    </div>
                </Backdrop>
            )}
            {showWhiteScreen && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <CircularProgress color="inherit" sx={{m:2}} />
                        <Typography variant="46" component="div">
                            {backdropText}
                        </Typography>
                    </div>
                </div>
            )}
        </div>
    );
}
