import React from 'react';
export const NotificationContext = React.createContext(
    {
        open: false,
        setOpen: () => { },
        message: "default message",
        severity: "success",
        sendMessage: (severity,message) => { }
    });
export function NotificationProvider(props) {
    const [snackPack, setSnackPack] = React.useState([]);
    const [message, setMessage] = React.useState(undefined)
    const [open, setOpen] = React.useState(false)
    React.useEffect(() => {
        if (snackPack.length && !message) {
            // Set a new snack when we don't have an active one
            setMessage({ ...snackPack[0] });
            setSnackPack((prev) => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && message && open) {
            // Close an active snack when a new one is added
            setTimeout(()=>setOpen(false),200);
        }
    }, [snackPack, message, open]);
    function sendMessage(severity, message) {
        setSnackPack((prev) => [...prev, { severity,message, key: new Date().getTime() }]);
    }
    const handleExited = () => {
        setMessage(undefined);
    };
    return <NotificationContext.Provider value={{ open, setOpen, message, sendMessage, handleExited }}>{ props.children}</NotificationContext.Provider>
}